<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  },
  data() {
    return {
      marketing_campaign_areas: [],
      marketing_campaign_channels: [],
      data: {},
      selectedEvent: {},
      MarketingCampaigns: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMarketingCampaigns: null,
      MarketingCampaignsSearchMode: false,
      searchModel: "",
      campaigning: {},
      channel: {},
    };
  },
  methods: {
    addMarketingCampaigns() {
      this.data.marketing_campaign_channel_id = this.$route.params?.channelId;
      console.log({ data: this.data });
      this.http
        .post("marketing-campaign-channel-events", this.data, null, true)
        .then((res) => {
          if (res.status) {
            this.data = {};
            this.get(this.page);
            document.querySelector('#addModal .close-modal-btn').click()


          }
        });
    },
    editMarketingCampaigns() {
      console.log({ selectedEvent: this.selectedEvent });
      this.http
        .put(
          "marketing-campaign-channel-events",
          this.selectedEvent?.id,
          this.selectedEvent
        )
        .then(() => {
          this.data = {};
          this.get(this.page);
          setTimeout(() => {
            document.querySelector("#editModal .close-modal-btn").click();
          }, 200);
        });
    },
    search() {
      this.MarketingCampaignsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("marketing-campaign-channel-events/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        }, null, true)
        .then((res) => {
          this.MarketingCampaigns = res.data;
          console.log("######## files", this.MarketingCampaigns);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.MarketingCampaignsSearchMode = false;
      this.get(this.page);
    },
    getCampaignAreas() {
      this.http
        .post("marketing-campaign-areas/by-campaign-id", {
          marketing_campaign_id: this.$route.params?.id,
        }, null, true)
        .then((res) => {
          this.marketing_campaign_areas = res.data;
        });
    },
    getMarketingCampaigns() {
      this.http.get("marketing-campaigns", null, null, true).then((res) => {
        this.MarketingCampaigns = res.data;
      });
    },
    deleteMarketingCampaigns(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http
            .delete("marketing-campaign-channel-events", app.id)
            .then(() => {
              console.log("############################3");
              this.get(this.page);
            });
        }
      });
    },
    getCampaign() {
      this.http
        .get("marketing-campaigns/" + this.$route.params?.id, null, null, true)
        .then((res) => {
          this.campaigning = res.data;
          console.log({ campaigning: this.campaigning });
        });
    },
    getChannel() {
      this.http
        .get("marketing-campaign-channels/" + this.$route.params?.channelId, null, null, true)
        .then((res) => {
          this.channel = res.data?.marketing_channel;
          console.log({ channel: this.channel });
        });
    },
    get(page) {
      console.log(page);
      this.http
        .post("marketing-campaign-channel-events/by-campaign-id/pagination", {
          limit: this.limit,
          page: page,
          channel_id: this.$route.params?.channelId,
        }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.MarketingCampaigns = res.data;
          // res.data.forEach((item) => {
          //   this.marketing_campaign_areas.push(item.marketing_campaign_areas);
          // });

          // res.data.forEach((item) => {
          //   this.marketing_campaign_channels.push(
          //     item.marketing_campaign_channels
          //   );
          // });
        });
    },
    setSelectedEvent(app) {
      if (app?.start_time) app.start_time = app.start_time.split("T")[0];
      if (app?.finish_time) app.finish_time = app.finish_time.split("T")[0];
      console.log({ selectedEvent: app });
      this.selectedEvent = JSON.parse(JSON.stringify(app))
    },
  },
  created() {
    this.getChannel();
    this.getCampaign();
    this.getCampaignAreas();
    this.get(1);
  },
};
</script>

<template>
  <div class="d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center">
      <router-link :to="{ name: 'marketing_campaign_management' }">
        <h4 class="m-0">
          {{
            $t(
              "menu.menuitems.marketing.subItems.marketing_campaign_management"
            )
          }}
        </h4>
      </router-link>
      <div class="mx-2">\</div>
      <router-link :to="{ name: 'marketing_campaign_management_details', params: { id: this.$route.params?.id } }">
        <div>{{ campaigning?.name }}</div>
      </router-link>
      <div class="mx-2">\</div>
      <div>{{ `${channel?.name} ${$t("marketing.events")}` }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button class="btn-close text-light" v-if="MarketingCampaignsSearchMode" @click="cancelappsearchMode()"></button>
      <button type="button" data-bs-toggle="modal" data-bs-target="#addModal" class="btn btn-light float-end mb-4">
        <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">{{ $t("marketing.id") }}</th>
        <th scope="col">{{ $t("marketing.name") }}</th>
        <th scope="col">{{ $t("marketing.descr") }}</th>
        <th scope="col">{{ $t("marketing.location") }}</th>
        <th scope="col">{{ $t("marketing.marketing_area") }}</th>
        <th scope="col">{{ $t("marketing.total_cost") }}</th>
        <th scope="col">
          {{ $t("marketing.campaigns_management.start_time") }}
        </th>
        <th scope="col">
          {{ $t("marketing.campaigns_management.finish_time") }}
        </th>
        <th scope="col">{{ $t("marketing.created") }}</th>
        <th scope="col">{{ $t("marketing.updated") }}</th>
        <th scope="col">{{ $t("marketing.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(app, i) in MarketingCampaigns" :key="app" class="text-center">
        <td>{{ i + 1 }}</td>
        <td>{{ app?.name }}</td>
        <td>{{ app?.descr ? app?.descr : "--" }}</td>
        <td>{{ app?.location ? app?.location : "--" }}</td>
        <td>
          {{ app?.marketing_area?.name ? app?.marketing_area?.name : "--" }}
        </td>
        <td>{{ app?.total_cost }}</td>
        <td>{{ app?.start_time?.split("T")[0] }}</td>
        <td>{{ app?.finish_time?.split("T")[0] }}</td>
        <td>{{ app?.created?.split("T")[0] }}</td>
        <td>{{ app?.updated?.split("T")[0] }}</td>

        <td class="d-flex justify-content-end">
          <router-link
            :to="{ name: 'marketingCampaignChannelsEvents', params: { id: this.$route.params?.id, channelId: this.$route.params?.channelId, eventId: app?.id } }">
            <button class="btn btn-info mx-1" role="button" data-toggle="tooltip" data-placement="top"
              :title="app?.name + ' ' + $t('popups.details')">
              <i class="bx bxs-detail"></i>
            </button>
          </router-link>
          <a class="btn btn-primary mx-1" data-bs-target="#editModal" data-bs-toggle="modal"
            @click="setSelectedEvent(app)" href="javascript: void(0);" role="button">{{ $t("popups.edit") }}</a>
          <a class="btn btn-danger" href="javascript: void(0);" role="button" @click="deleteMarketingCampaigns(app)">{{
            $t("popups.delete") }}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--Start Add Modal-->
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addMarketingCampaigns()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.add") }}
            </h5>

          </div>

          <div class="modal-body">
            <label for="name">{{ $t("popups.name") }}</label>
            <input v-model="data.name" id="name" required class="form-control mb-2" type="text"
              :placeholder="$t('popups.name')" />

            <label for="descr">{{ $t("popups.descr") }}</label>
            <input v-model="data.descr" id="descr" class="form-control mb-2" type="text"
              :placeholder="$t('popups.descr')" />

            <label for="location">{{ $t("popups.location") }}</label>
            <input v-model="data.location" id="location" class="form-control mb-2" type="text"
              :placeholder="$t('popups.location')" />

            <label for="total_cost">{{ $t("popups.total_cost") }}</label>
            <input v-model="data.total_cost" id="total_cost" required class="form-control mb-2" type="number"
              :placeholder="$t('popups.total_cost')" />
            <label for="total_cost">{{ $t("popups.marketing_area") }}</label>
            <select v-model="data.marketing_area_id" class="mb-2 form-control">
              <option v-for="item in marketing_campaign_areas" :key="item?.id" :value="item.id">
                {{ item?.marketing_area?.name }}
              </option>
            </select>

            <label for="start_time">{{ $t("popups.start_time") }}</label>
            <input v-model="data.start_time" id="start_time" required class="form-control mb-2" type="date"
              :placeholder="$t('popups.start_time')" />

            <label for="finish_time">{{ $t("popups.finish_time") }}</label>
            <input v-model="data.finish_time" id="finish_time" required class="form-control mb-2" type="date"
              :placeholder="$t('popups.finish_time')" />
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.add") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!--Start Edit Modal-->
  <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editMarketingCampaigns()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.edit") }}
            </h5>

          </div>

          <div class="modal-body">
            <label for="name">{{ $t("popups.name") }}</label>
            <input v-model="selectedEvent.name" id="name" required class="form-control mb-2" type="text"
              :placeholder="$t('popups.name')" />

            <label for="descr">{{ $t("popups.descr") }}</label>
            <input v-model="selectedEvent.descr" id="descr" class="form-control mb-2" type="text"
              :placeholder="$t('popups.name')" />

            <label for="location">{{ $t("popups.location") }}</label>
            <input v-model="selectedEvent.location" id="location" class="form-control mb-2" type="text"
              :placeholder="$t('popups.location')" />

            <label for="total_cost">{{ $t("popups.total_cost") }}</label>
            <input v-model="selectedEvent.total_cost" id="total_cost" required class="form-control mb-2" type="number"
              :placeholder="$t('popups.total_cost')" />
            <label for="total_cost">{{ $t("popups.marketing_area") }}</label>
            <select v-model="selectedEvent.marketing_area_id" class="mb-2 form-control">
              <option v-for="item in marketing_campaign_areas" :key="item?.id" :value="item.id">
                {{ item?.marketing_area?.name }}
              </option>
            </select>

            <label for="start_time">{{ $t("popups.start_time") }}</label>
            <input v-model="selectedEvent.start_time" id="start_time" required class="form-control mb-2" type="date"
              :placeholder="$t('popups.start_time')" />

            <label for="finish_time">{{ $t("popups.finish_time") }}</label>
            <input v-model="selectedEvent.finish_time" id="finish_time" required class="form-control mb-2" type="date"
              :placeholder="$t('popups.finish_time')" />
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.edit") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Edit Modal-->


  <!--   Apps  Pagination     -->
  <ul class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == tot_pages }">
      <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>

  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
